import React from "react";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import "../css/contact.css";

const Contact = () => {
  return (
    <section id="contact" className="contact-section">
      <h2 className="contact-heading">Kontakt</h2>
      <div className="contact-info">
        <p>
          Szkoła Muzyki i Tańca Art Music
        </p>
        <p>
          Ul. Piwna 1, 12-200 Działdowo
        </p>
        <p>
          +48 696-226-278
        </p>
      </div>
      <div className="social-icons">
        <a
          href="https://www.instagram.com/artmusicszkola/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon instagram"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.facebook.com/SzkolaMuzykiiTancaArtMusic"
          target="_blank"
          rel="noopener noreferrer"
          className="icon facebook"
        >
          <FaFacebook />
        </a>
      </div>
    </section>
  );
};

export default Contact;
