import { useEffect, useState } from "react";
import { About } from "./Components/About";
import { Header } from "./Components/Header";
import Contact from "./Components/Contact";
import Offer from "./Components/Offer";
import BackToTopButton from "./Components/BackToTopButton";
import FullScreenImage from "./Components/FullScreenImage";
import Modal from "./Components/Modal";  // Import the Modal component
import AOS from "aos";
import "./css/main.css";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');

  const openFullScreen = (image, images) => {
    setSelectedImage(image);
    setCurrentImages(images);
    setIsFullScreen(true);
  };

  const nextImage = () => {
    const currentIndex = currentImages.indexOf(selectedImage);
    const nextIndex = (currentIndex + 1) % currentImages.length;
    setSelectedImage(currentImages[nextIndex]);
  };

  const prevImage = () => {
    const currentIndex = currentImages.indexOf(selectedImage);
    const prevIndex = (currentIndex - 1 + currentImages.length) % currentImages.length;
    setSelectedImage(currentImages[prevIndex]);
  };

  const openModal = (offer, category) => {
    setSelectedOffer(offer);
    setCurrentCategory(category);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOffer('');
    setCurrentCategory('');
  };

  return (
    <div className="App">
      <Header />
      <About />
      {/* <PianoKeyboard /> */}
      <section data-aos="fade-up">
        <Offer
          openFullScreen={openFullScreen}
          openModal={openModal}
        />
      </section>
      <Contact />
      {isFullScreen && (
        <FullScreenImage
          selectedImage={selectedImage}
          closeFullScreen={() => setIsFullScreen(false)}
          nextImage={nextImage}
          prevImage={prevImage}
        />
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          offer={selectedOffer}
          category={currentCategory}
        />
      )}
      <BackToTopButton />
    </div>
  );
}

export default App;
