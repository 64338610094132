const imagesContext = require.context('../assets/modalImages/resized/', false, /\.(png|jpe?g|svg)$/);

const imgMap = imagesContext.keys().reduce((images, file) => {
  
  const fileName = file.match(/\/([^/]+)\.(png|jpe?g|svg)$/)[1];
  const key = fileName;
  
  images[key] = imagesContext(file);
  return images;
}, {});

export default imgMap;
