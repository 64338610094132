export const danceOffers = [
    'Lollipops (2-3 lata)',
    'Dance4Fun (4-5 lat)',
    'SweetDreams (4-6 lat)',
    'MyAngels (6-7 lat)',
    'Volt (8-10 lat)',
    'Dance Machine (10-13 lat)',
    'Taniec Towarzyski dla Par',
    'Ladies Latino',
    'Exotic Dance',
  ];

export const sportFitnessOffers = [
    'Slavica Dance&Fitness',
    'Zumba',
    'Techno Joga',
    'Fit Sylwetka',
    'Pilates',
    'Hula Hop Power',
    'Zdrowe Plecy',
    'Mały Fighter - kickboxing (3-5 lat)',
    'CrossFit Kids (4-8 lat)',
    'Gimnastyka artystyczna i akrobatyka (5-12 lat)',
    'Pole Dance',
    'Pole Dance Kids',
    'Step Fitness 50+',
  ];

export const artisticOffers = [
    'Zaczarowane Pędzelki - warsztaty plastyczne',
    'Młodzi Artyści - warsztaty plastyczne',
    'Szkoła Rysunku',
    'Indywidualne lekcje malarstwa',
  ];

  export const instrumentalOffers = [
    'Fortepian',
    'Skrzypce',
    'Gitara klasyczna, akustyczna, elektryczna, basowa',
    'Perkusja',
    'Ukulele',
  ];
  
  export const musicOffers = [
    'W krainie dźwięków (1-3 lat)',
    'Baby English (1-3 lat)',
    'Muzyczny Angielski (3-6 lat)',
  ];

  export const vocalOffers = [
    'Indywidualne lekcje dla dzieci, młodzieży i dorosłych',
    'Grupowe zajęcia wokalne dla 6-7 latków',
    'Chór THE POWER OF SOUND',
  ];