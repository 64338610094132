import React from 'react';
import '../css/category.css';
import { Gallery } from './Gallery';

const Category = ({ categoryOffer, categoryImages, category, openFullScreen, openModal }) => {
  return (
    <div className="category-section" id='category'>
      <h2 className="category-title"><span>{category}</span></h2>
      <div className='category-content'>
        <div className="category-container">
          {categoryOffer.map((offer, offerIndex) => (
            <div key={offerIndex} className="category-item" onClick={() => openModal(offer, category)}>
              {offer}
            </div>
          ))}
        </div>
        <div className='images-container'>
          <Gallery
            sources={categoryImages}
            openFullScreen={(image) => openFullScreen(image, categoryImages)}
          />
        </div>
      </div>
    </div>
  );
};

export default Category;
