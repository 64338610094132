export const sportFitnessDescription = {
    'Slavica Dance&Fitness': 'Slavica Dance to trening fitness na ludowo. Jest to nowa forma aktywności fizycznej skierowana do wszystkich niezależnie do wieku. Zmieniamy kroki tańców ludowych w sposób jaki jeszcze nie było! Dynamiczne zajęcia, które dadzą upust Twoim emocjom, wytwarzać nieziemskie podkłady endorfin i pozytywnej energii. Slavica Dance to inaczej fitness na ludowo z niepowtarzalną folkową muzyką i fantastyczną energią. Zapraszamy!',
    'Zumba': 'Zumba to dynamiczny i energetyczny trening fitness, który łączy w sobie elementy tańca i aerobiku. Ta popularna forma aktywności fizycznej wywodzi się z Kolumbii i jest znana ze swojego rytmicznego charakteru. Zumba łączy w sobie różnorodne style taneczne, takie jak salsa, merengue, cumbia bachata i wiele innych, co sprawia, że jest nie tylko skuteczną formą ćwiczeń, ale także świetną zabawą. Wybuch endorfin po takim treningu jest gwarantowany!',
    'Techno Joga': 'Techno Joga to nowoczesne podejście, które łączy tradycje jogi z elementami współczesności, w tym dźwiękami elektronicznej muzyki i światłami ultrafioletowymi. Ta wyjątkowa praktyka integruje ciało, oddech i umysł, umożliwiając nam skoncentrowanie sie na energii płynącej z wewnątrz oraz odkrycie i uwolnienie naszych emocji. Podczas zajęć skupimy się na wyciszeniu myśli, praktykując obecność tu i teraz. Każdy uczestnik koncentruje sie na sobie i swoich możliwościach, stopniowo pokonując ograniczenia ciała. To nie tylko trening fizyczny, ale także inspiracja do głębszego poznania samego siebie i osiągnięcia harmonii w nowoczesnym świecie.',
    'Fit Sylwetka': 'Fit Sylwetka to zajęcia Fitness dla aktywnych kobiet. Treningi obejmują ćwiczenia wzmacniające mięśnie brzucha, ud i ramion. Każde zajęcia to ogromna porcja energii i spalone kalorie. Praca nad postawą, wzmocnienie ciała oraz utracone centymetry w obwodach to korzyści, które płyną z każdych zajęć! Cały trening uwieczniony jest strechingiem.',
    'Pilates': 'Pilates to trening, który wzmacnia wszystkie partie mięśniowe. Czerpie sporo z innych aktywności sportowych: tai-chi, jogi, baletu i gimnastyki. Trening ten ma na celu uelastycznienie mięśni, rozciągnięcie i wzmocnienie mięśni głębokich, poprawę postawy, obniżenie poziomu stresu, odciążenie kręgosłupa i poprawę zdrowia ćwiczących osób. Zapraszamy serdecznie na treningi pilates z Moniką!',
    'Hula Hop Power': 'Zajęcia Hula Hop Power to ogromna dawka endorfin i świetna zabawa, a wszystko przy użyciu obręczy, które fantastycznie modelują całą sylwetkę! Na każdym treningu zadbamy o Twoje ciało  - brzuch staje się bardziej płaski, mięśnie brzucha mocniejsze, a zbędne centymetry znikają! Podczas ćwiczeń zadbamy także o poprawną postawę, wzmocnimy mięśnie nóg i mięśnie kręgosłupa. Zajęcia Hula Hop Power to po prostu wulkan pozytywnej energii!',
    'Zdrowe Plecy': 'Zajęcia Zdrowe Plecy to zajęcia stworzone w trosce o dobre samopoczucie ciała i ducha. Specjalnie opracowane ćwiczenia redukują napięcia nagromadzone w naszym ciele, wzmacniają mięśnie głębokie i redukują stres. Zajecia wprowadzą nasze ciała w harmonię, spowodują odprężenie, a praca nad oddechem pozytywnie wpłynie na dobre samopoczucie.',
    'Mały Fighter - kickboxing (3-5 lat)': 'Mały Fighter to ogólnorozwojowe zajęcia z elementami kickboxingu i boksu. Na zajęciach większość ćwiczeń wykonywanych jest w formie zabawy, tak aby kształtować od najmłodszych lat cechy motoryczne dzieci. Sporty walki są doskonałym sportem dla wszystkich, nawet tych najmłodszych dla których zabawa jest najlepsza forma nauki. Zapraszamy małych adeptów do rozpoczęcia fantastycznej sportowej przygody!',
    'CrossFit Kids (4-8 lat)': 'CrossFit Kids to bezpieczna formuła zajęć sportowych dla dzieci nastawiona na doskonalenia sprawności fizycznej, koordynacji ruchowej oraz integracji i zabawy z rówieśnikami ! Podczas zajęć dowiemy się dlaczego i jak działa nasze ciało. Wszystko w formie fantastycznej zabawy!',
    'Gimnastyka artystyczna i akrobatyka (5-12 lat)': 'Zajęcia z gimnastyki artystycznej i akrobatyki są doskonałym sposobem na aktywność fizyczną. Dzięki niej dziecko otrzymuje dużą dawkę ruchu oraz przyzwyczaja się do regularnego uprawiania sportu. Treningi wzmacniają wiele grup mięśniowych, poprawiają zwinność, skoczność oraz utrzymywanie równowagi. Nauka figur gimnastycznych odbywa się pod okiem licencjonowanego instruktora, który zadba o bezpieczeństwo i poprawne wykonywanie ćwiczeń.',
    'Pole Dance': 'Zajęcia pole dance to niezwykle sportowa przygoda. Wyszczupla ciało, modeluje sylwetkę, wzmacnia mięśnie i ogólnie poprawia całą kondycję. Oprócz tego, co szczególnie docenią osoby,  którym zależy na elastyczności, pole dance poprawia rozciągnięcie i ogólną elastyczność naszego ciała. Taniec na rurze kształtuje brzuch, plecy, barki, uda, łydki oraz wzmacnia wszystkie mięśnie przykręgosłupowe. Na zajęciach nauczymy się także figur dostosowanych do poziomu uczestników zajęć oraz nauczymy się choreografii.',
    'Pole Dance Kids': 'Pole Dance Kids to sport, który uczy pracy, wytrwałości, a przy okazji pozwala na bardzo dobrą zabawę. Pole Dance dla dzieci to dyscyplina sportowa łącząca elementy akrobatyki, gimnastyki oraz tańca. Pole dance to dynamiczny i ekscytujący sport. Wymaga siły oraz sprawności, którą nabywa się w miarę kolejnych zajęć. Podczas zajęć dzieci poznają kolejne elementy z wykorzystaniem rury – pionowo zamontowanego metalowego drążka. Pracują nad koordynacją, siłą, oraz rozciągnięciem, tak, aby technicznie wykonywać coraz bardziej skomplikowane elementy.',
    'Step Fitness 50+': 'Step Fitness 50+ to zajęcia aerobowe typu kardio dla dojrzałych Pan, która chcą zadbać o swoje zdrowie i lepsze samopoczucie. Jest to doskonały sposób, by zrzucić zbędne kilogramy i poprawić swoją kondycję, ponieważ ćwiczenia mają bardzo intensywny charakter.Ćwiczenia doskonale angażują wszystkie mięśnie nóg oraz pośladków, dzięki czemu podczas step aerobiku modelują się uda, pośladki oraz łydki, zyskując na sprężystości i jędrności. Zajęcia dostosowane są do możliwości kondycyjnych i ruchowych dojrzałych kobiet Gwarantujemy dużą dawkę ruchu, endorfin i świetniej zabawy! ',
  };

  export const danceDescription = {
    'Lollipops (2-3 lata)': "Zajęcia taneczne dla Maluszków, które pragną rozpocząć swoją przygodę z tańcem. To fantastyczna okazja do wspólnego spędzenia czasu z mamą lub tatą, bo w zajęciach aktywny udział biorą również rodzice. Zajęcia w grupie Piruecik to pierwsze próby naśladowania gestów i ruchów, pierwsze poznane kroki, świetna zabawa, doskonalenie koordynacji ruchowej oraz kontakt z rówieśnikami.",
    'Dance4Fun (4-5 lat)': "Na zajęcia zapraszamy młodych Tancerzy, którzy kochają energiczną muzykę i dużą dawkę ruchu. Gwarantujemy, że zabierzemy Was w niezwykły świat tańca nowoczesnego. Na zajęciach poznajcie nowe kroki, stworzymy niepowtarzalne choreografię a przy tym wszystkim będziemy się świetnie bawić!",
    'SweetDreams (4-6 lat)': "Na zajęcia zapraszamy młode Tancerki, które pragną poznać tajniki tańca klasycznego. Na zajęciach poznamy pozycje rąk i nóg oraz podstawowe kroki baletowe. Popracujemy nad prawidłową postawą ciała oraz koordynacją ruchową. Zajęcia rozwiną także w młodych artystkach wrażliwość na muzykę, poczucie piękna, pobudzą ich wyobraźnię oraz poczucie przestrzeni.",
    'MyAngels (6-7 lat)': "Na zajęcia zapraszamy wszystkich młodych tancerzy, którym taniec w sercach gra. W naszych choreografiach wykorzystamy elementy tańca klasycznego i tańca współczesnego i nowoczesnego.Nie zabraknie ekspresyjnych choreografii i zabaw tanecznych!",
    'Volt (8-10 lat)': "Grupa Volt to taneczna przygoda dla osób, które chcą rozpocząć swoją przygodę z tańcem nowoczesnym i disco dance. Energetyczne choreografie, ogromna dawka endorfin i świetna zabawa. Popracujemy również nad ekspresją tańca, synchronizacją ruchów oraz improwizacją ruchową.",
    'Dance Machine (10-13 lat)': "Grupa Dance Machine zaprasza roztańczoną młodzież, która pragnie doskonalić swój warsztat taneczny. Na zajęciach obracać będziemy się wokół różnych styli tanecznych: tańca nowoczesnego, street dance i disco dance. Nie zabraknie świetniej zabawy oraz nowych doświadczeń tanecznych!",
    'Taniec Towarzyski dla Par': "Zapraszamy serdecznie wszystkich miłośników tańca, aby odkryć w sobie duszę króla i królowej parkietu. Gwarantujemy świetną zabawę oraz niezapomnianą przygodę taneczną ze swoim partnerem. Plan kursu tańca towarzyskiego obejmuję naukę podstawowych kroków oraz figur z zakresu tańców standardowych, latynoamerykańskich oraz tańca użytkowego.  Podczas trwania zajęć zajmiemy się także nauką partnerowania, pracą nad własnym ciałem i poprawną postawą.",
    'Ladies Latino': "To zajęcia dla Pań, które kochają latynoskie rytmy. Podczas zajęć dowiesz się jak pracować z własnym ciałem, jakie są jego mocne strony i jak je wykorzystać w tańcu. Na zajęciach nauczymy się podstawowych figur i kroków tańców latynoamerykańskich takich jak: samba, rumba, sala, bachata, cha-cha czy paso doble. Nie zabraknie też zmysłowych choreografii, pełnych latynoskiego charakteru. Zajęcia Ladies Latino to także świetny trening dla ciała, najbardziej zaangażowanie partie ciała to nogi, brzuch i pośladki. Trening wspomaga zdolność lepszej kontroli i świadomości swojego ciała.",
    'Exotic Dance': "Odkryj swoją kobiecą energię i pozwól się porwać tańcu egzotycznemu! Te zajęcia mogą wzmocnić twoją pewność siebie i pomóc uwolnić nagromadzone emocje. Popraw swoje samopoczucie, zwiększ kontakt z własnym ciałem, spraw, aby ruchy były bardziej płynne. Zajęcia te pomagają w elastyczności, rozluźnieniu i rytmie całego ciała. Wyrażaj siebie poprzez taniec i poczuj wolność w ruchu. Zajęcia Exotic Dance to podróż w świat tańca orientalnego o tańca brzucha. Dołącz do nas i odkryj nowy wymiar tanecznej ekspresji!",
  };

  export const instrumentalDescription = {
    'Fortepian': "Naukę gry na fortepianie rozpocząć można w każdym wieku, dlatego serdecznie zapraszamy osoby dorosłe, młodzież i dzieci od 5 roku życia. Zapewniamy rozrywkowy repertuar, indywidualne podejście do każdej osoby oraz gwarantujemy niezapomnianą przygodę z muzyką ! Podczas nauki gry na fortepianie kładziemy nacisk na indywidualny rozwój, doskonalenie techniki gry na instrumencie oraz współpracę z innymi muzyki.",
    'Skrzypce': 'Zapraszamy gorąco dzieci od 5 roku życia oraz dorosłych, którzy marzą by zatopić się w niesamowitym brzmieniu skrzypiec. Zapewniamy indywidualne podejście do ucznia oraz naukę od podstaw. Istnieje możliwość wypożyczenia instrumentu w rozmiarze 1/4, 2/4, 3/4 i 4/4.',
    'Gitara klasyczna, akustyczna, elektryczna, basowa': 'Gitarowe trio czyli gitara klasyczna, akustyczna, elektryczna i basowa. Zapraszamy osoby w każdym wieku na indywidualne lekcje nauki gry na gitarze. Jeżeli nie potrafisz się zdecydować na której gitarze chciałbyś grać, to żaden problem. Nasza szkoła wychodzi naprzeciw Twoim potrzebom i oczekiwaniom, u nas spróbować możesz wszystkiego. Naszym celem jest rozwój i doskonalenie Twojej techniki wykonawczej oraz wykorzystanie możliwości brzmieniowych jakie daje gitara. Na zajęciach nauki gry na gitarze zabierzemy Cię w podróż po różnorodnych stylach muzycznych: od rocka, bluesa, jazzu, popu po metal i muzykę fusion. Dla naszych najmłodszych gitarzystów przygotowaliśmy mniejsze gitary w rozmiarze 2/4 i 3/4.',
    'Perkusja': 'Nauka gry na perkusji, to propozycja zajęć dla osób, które chcą poznać tajniki rytmu. Zapraszamy dzieci od 7 roku życia, młodzież i osoby dorosłe. Na zajęciach uczniowie zapoznają się z zestawem perkusyjnym i podstawami gry na tym instrumencie. Osobom, którym perkusja jest znana, pozwolą rozwinąć umiejętności techniczne i interpretacyjne. Lekcje gry na perkusji niewątpliwie rozwijają poczucie rytmu i kształtują wyobraźnię muzyczną. Są fantastycznym wprowadzeniem do muzykowania zespołowego.',
    'Ukulele': 'Do nauki gry na ukulele zapraszamy osoby zaczynające swoją przygodę z ukulele jak i bardziej zaawansowane – nauka będzie dostosowana do umiejętności uczniów. W szczególności zapraszamy dzieci, dla których instrument ten jest dużo łatwiejszy niż gitara klasyczna. Nauka gry na ukulele obejmuje naukę akordów, naukę bicia oraz tworzenie  prostych melodii i łączenie gry se śpiewem.'
  };

  export const vocalDescription = {
    'Indywidualne lekcje dla dzieci, młodzieży i dorosłych': 'Na indywidualne lekcje śpiewu zapraszamy dzieci, młodzież oraz osoby dorosłe, które chcą doskonalić swój warsztat wokalny. Zajęcia wokalne obejmują naukę poprawnej emisji głosu, w tym prawidłowe ustawienie aparatu głosowego w mowie i śpiewie, pracę nad techniką śpiewu, intonacją, dykcją i interpretacją piosenek. Praca odbywa się przy akompaniamencie fortepianu i podkładów muzycznych. Wszystkim uczestnikom zajęć wokalnych zapewniamy profesjonalną opiekę artystyczną oraz udział w konkursach wokalnych.',
    'Grupowe zajęcia wokalne dla 6-7 latków': 'W ramach zajęć grupowych oferujemy pracę w niewielkich 2-3 osobowych grupach. Zajęcia obejmują pracę nad emisją głosu, pracę nad głosem przy dźwiękach fortepianu, kształcenie słuchu muzycznego, wrażliwości i intonacji. Wszystko w formie muzycznej przygody z mikrofonem i stawiania pierwszych kroków na scenie muzycznej.',
    'Chór THE POWER OF SOUND': 'Na próby chóru zapraszamy wszystkie osoby, którym muzyka w sercach gra. Obiecujemy niezapomnianą przygodę wokalną wśród fantastycznego teamu. W chórzystkach chóru The Power of Sound drzemie siła kobiecych głosów, wielka energia i charyzma! Dołącz do nas!',
  };

  export const musicDescription = {
    'W krainie dźwięków (1-3 lat)': 'Kochane Maluszki zapraszamy Was do krainy pełnej muzycznych doświadczeń i inspiracji! Zajęcia W Krainie Dźwięków to wyjątkowy czas dla Twojego dziecka, który wzbogaci jego muzyczny świat. Zajęcia uwrażliwiają dziecko na poszczególne elementy dzieła muzycznego: agogikę, rytmikę, artykulację, dynamikę i barwę. To też poznanie bogatego świata skal i rytmów, a wszystko w formie muzycznych opowieści, zabaw i niezwykłych zaczarowanych melodii.',
    'Baby English (1-3 lat)': 'Zajęcia Baby English to zajęcia muzyczno-językowe dla maluszków od 1 do 3 roku życia. Podczas wspólnej zabawy najmłodsi osłuchują się z językiem, przyswajają słowa i zwroty w języku angielskim przy jednoczesnym rozwoju sprawności ruchowej. Wszystko w rytm muzyki, piosenek i dwusetnej zabawy wspólnie z rodzicami!',
    'Muzyczny Angielski (3-6 lat)': 'Zajęcia Muzyczny Angielski obejmują naukę języka obcego przy dźwiękach muzyki. To najlepszy sposób na przyswajanie nowych słów i zwrotów. Niekończące się zabawy muzyczno-ruchowe, instrumentacja piosenek i poznawanie nowych brzmień. Zapraszamy serdecznie dzieci w wieku przedszkolnym od 3 do 6 roku życia. Zajęcia poprowadzi certyfikowany nauczyciel języka angielskiego.',
  };

  export const artisticDescription = {
    'Zaczarowane Pędzelki - warsztaty plastyczne': 'Twórczość plastyczna jest dla dziecka źródłem radości, dobrej zabawy, poznawania i wyrażania siebie. Dzięki rysowaniu dzieci odkrywają świat. Zabawy plastyczne dla dzieci mają wiele zalet, m.in.: kształcą zdolności manualne, które wykorzysta później do nauki pisania. Zajęcia plastyczne pozwalają na poprawę koordynacji wzrokowo-ruchowej. Dzięki różnorodności materiałów plastycznych dziecko może stymulować swoje zdolności twórcze, rozwija fantazję, poprawia myślenie, poszukuje nowych rozwiązań i skojarzeń. Na zajęcia zapraszamy młodszych miłośników sztuki plastycznej!',
    'Młodzi Artyści - warsztaty plastyczne': 'Na zajęcia zapraszamy młodzież, których dusze otwarte są na wszelakie działania artystyczne. Uczestnicy zajęć będą mieli okazję poznać różnorodne techniki malarskie, rysunkowe i rzeźbiarskie. Celem zajęć jest uwrażliwienie na piękno i sztukę, rozwijanie zdolności manualnych, wyobraźni i kreatywności. ',
    'Szkoła Rysunku': 'Zajęcia z rysunku dla dzieci starszych i młodzieży, które umożliwiają zapoznanie się z wszystkimi podstawowymi zagadnieniami rysunku. Rysunek to dziedzina, której znajomość stanowi fundament warsztatu artysty. Dzięki niej możliwe jest wyrażenia tego co ulotne w wyobraźni i dzielenie się naszymi wizjami z innymi. Rysunek daje możliwość tworzenia samodzielnych dzieł estetycznych jak również  kreowania zaawansowanych projektów wymagających wstępnego zaplanowania czy projektowania. To nauka nie tylko tego jak trzymać ołówek, węgiel czy flamaster - to umiejętność widzenia form i przestrzeni oraz zdolność przekazywania tych informacji dalej w formie obrazowej. Zajęcia prowadzi wykwalifikowany pedagog- absolwentka ASP w Gdańsku.',
    'Indywidualne lekcje malarstwa': 'Indywidualne lekcje malarstwa to zajęcia praktyczne, podczas których uczestnicy zajęć poznają tajniki technik malarskich. Kurs malarstwa jest skierowany do wszystkich osób początkujących oraz do tych, które chcą doskonalić swoje umiejętności plastyczne. Wybrane zajęcia wzbogacone są o wiedzę na temat perspektywy, relatywizmie i temperaturach barw, o budowaniu głębi w obrazie, konstrukcji, kompozycji i formie.'
  };