import React, { useState } from "react";
import "../css/header.css";
import { Link } from "react-scroll";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    if (isOpen) {
      setIsOpen(false); // Zamknij menu, gdy jest otwarte
    }
  };

  return (
    <header>
      <nav className="navbar">
        <div className="logo">
            <div className="logo"></div>
        </div>
        <ul className={isOpen ? "nav-links open" : "nav-links"}>
          <li>
            <Link
              to="about"
              smooth={true}
              duration={500}
              onClick={handleLinkClick}
            >
              O nas
            </Link>
          </li>
          <li>
            <Link
              to="offer"
              smooth={true}
              duration={500}
              onClick={handleLinkClick}
            >
              Oferta zajęciowa
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              onClick={handleLinkClick}
            >
              Kontakt
            </Link>
          </li>
          <li>
            <a href="https://librusgo.pl/course-group-sign-up/bef0be2ee32c" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={handleLinkClick}>
                Zapisz się na zajęcia  
              </a>
          </li>
        </ul>
        <div className="burger" onClick={toggleNav}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </header>
  );
};
