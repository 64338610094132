import React, { useState } from 'react';
import {
  artystyczne,
  sportFitness,
  taniec,
  instrumentalne,
  muzyczne,
  wokalne,
} from '../utils/imageImports';
import { instrumentalOffers, sportFitnessOffers, artisticOffers, musicOffers, vocalOffers, danceOffers } from '../data/categories';
import '../css/offer.css';
import Category from './Category';
import { Link } from 'react-scroll';

const imageSources = {
  "Taniec": taniec,
  'Sport i Fitness': sportFitness,
  'Zajęcia instrumentalne': instrumentalne,
  'Zajęcia wokalne': wokalne,
  'Zajęcia muzyczne': muzyczne,
  'Zajęcia artystyczne': artystyczne,
};

const Offer = ({ openFullScreen, openModal }) => {
  const [selectedCategory, setSelectedCategory] = useState('Taniec');

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  const renderCategoryComponent = () => {
    switch (selectedCategory) {
      case 'Taniec':
        return (
          <Category
            categoryOffer={danceOffers}
            categoryImages={taniec}
            category="Taniec"
            openFullScreen={openFullScreen}
            openModal={openModal}
          />
        );
      case 'Sport i Fitness':
        return (
          <Category
            categoryOffer={sportFitnessOffers}
            categoryImages={sportFitness}
            category="Sport i Fitness"
            openFullScreen={openFullScreen}
            openModal={openModal}
          />
        );
      case 'Zajęcia instrumentalne':
        return (
          <Category
            categoryOffer={instrumentalOffers}
            categoryImages={instrumentalne}
            category="Zajęcia instrumentalne"
            openFullScreen={openFullScreen}
            openModal={openModal}
          />
        );
      case 'Zajęcia wokalne':
        return (
          <Category
            categoryOffer={vocalOffers}
            categoryImages={wokalne}
            category="Zajęcia wokalne"
            openFullScreen={openFullScreen}
            openModal={openModal}
          />
        );
      case 'Zajęcia muzyczne':
        return (
          <Category
            categoryOffer={musicOffers}
            categoryImages={muzyczne}
            category="Zajęcia muzyczne"
            openFullScreen={openFullScreen}
            openModal={openModal}
          />
        );
      case 'Zajęcia artystyczne':
        return (
          <Category
            categoryOffer={artisticOffers}
            categoryImages={artystyczne}
            category="Zajęcia artystyczne"
            openFullScreen={openFullScreen}
            openModal={openModal}
          />
        );
      default:
        return (
          <Category
            categoryOffer={danceOffers}
            categoryImages={taniec}
            category="Taniec"
            openFullScreen={openFullScreen}
            openModal={openModal}
          />
        );
    }
  };

  return (
    <section id="offer" className="offer-section">
      <div className="offer-menu">
        <div className="container">
          <h2>Oferta zajęciowa</h2>
          <ul className="offer-list">
            {Object.keys(imageSources).map((category) => (
              <li key={category} className={`offer-list-item ${
                selectedCategory === category ? 'active' : ''
              }`}>
<Link
                to='category'
                smooth={true}
                duration={1000}
                offset={-25}
                className='offer-list-item-link'
                onClick={() => handleClick(category)}
              >
                {category}
              </Link>
              </li>
              
            ))}
          </ul>
        </div>
      </div>
      <div className="category-transition">
        <div className="container">
            {renderCategoryComponent()}
        </div>
      </div>
    </section>
  );
};

export default Offer;
