import React, { useEffect, useRef } from "react";
import "../css/about.css";

export const About = () => {
  const aboutSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 } // Ustaw próg widoczności
    );

    const elements = aboutSectionRef.current.querySelectorAll(".fade-in-slide-in, .fade-in-slide-in1, .fade-in-slide-in2, .fade-in-slide-in3");
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="background-shadow">
    <section id="about" className="about-section" ref={aboutSectionRef}>
      
      <div className="container">
        <h1 className="section-title fade-in-slide-in">O Nas</h1>
        <div className="content">
          <div className="text">
            <p className="fade-in-slide-in1">
              Szkoła Muzyki i Tańca <strong className="highlight">„Art Music”</strong> to szkoła artystyczna powstała w
              2017 roku w <strong className="highlight">Działdowie</strong>. Jej twórcą i założycielką jest <strong className="highlight">Kornelia Falk</strong>.
              Miejsce to zostało stworzone z myślą o rozwijaniu zdolności
              muzycznych, tanecznych i artystycznych dzieci, młodzieży i osób dorosłych.
            </p>
            <p className="fade-in-slide-in2">
              Szkoła z roku na rok rozwija się, tworząc własne projekty
              muzyczne, taneczne, imprezy kulturalne oraz płyty z własnym
              materiałem dźwiękowym. Uczniowie szkoły biorą udział w licznych
              konkursach tanecznych i wokalnych, reprezentując miasto i szkołę na
              terenie powiatu działdowskiego i województwa. „Z pasji do
              nauczania” to motto, które przyświeca wszystkim działaniom szkoły.
            </p>
            <p className="fade-in-slide-in3">
              Zapraszamy serdecznie do <strong className="highlight">Art Music</strong>!
            </p>
          </div>
        </div>
      </div>
      
      
    </section>
    </div>
  );
};
