import React from 'react'
import "../css/modal.css"
import { danceDescription, sportFitnessDescription, instrumentalDescription, vocalDescription, musicDescription, artisticDescription } from '../data/offerDescriptions';
import imgMap from '../data/imagesForModal';


const Modal = ({ isOpen, onClose, offer, category }) => {
    if (!isOpen) return null;

    const descriptions = {
        'Taniec': danceDescription,
        'Sport i Fitness': sportFitnessDescription,
        'Zajęcia instrumentalne': instrumentalDescription,
        'Zajęcia wokalne': vocalDescription,
        'Zajęcia muzyczne': musicDescription,
        'Zajęcia artystyczne': artisticDescription,
      }[category] || {};

    const imgURL = imgMap[offer];

    return (
        <div className="modal-overlay">
        <div className="modal-content">
          <h2>{offer}</h2>
          <div className="modal-content-container">
            <p>{descriptions[offer] || 'Brak opisu dla tej oferty.'}</p>
            <img src={imgURL} alt={`Zdjęcie do oferty ${offer}`} />
          </div>
          <button onClick={onClose}>Zamknij</button>
        </div>
      </div>
    );
  };

export default Modal;
