// src/components/FullScreenImage.js
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import '../css/fullScreenImage.css';

const FullScreenImage = ({ selectedImage, closeFullScreen, nextImage, prevImage }) => {

  const handlers = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => prevImage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="full-screen-overlay" onClick={closeFullScreen} {...handlers}>
      <button className="arrow left-arrow" onClick={(e) => { e.stopPropagation(); prevImage(); }}>&#10094;</button>
      <img src={selectedImage} alt="Full Screen" className="full-screen-image" />
      <button className="arrow right-arrow" onClick={(e) => { e.stopPropagation(); nextImage(); }}>&#10095;</button>
      <button className="close-button" onClick={(e) => { e.stopPropagation(); closeFullScreen(); }}>×</button>
    </div>
  );
};

export default FullScreenImage;
