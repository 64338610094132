function importAll(r) {
  return r.keys().map(r);
}

const artystyczne = importAll(
  require.context("../assets/zajeciaArtystyczne", false, /\.(png|jpe?g|svg)$/)
);

const sportFitness = importAll(
  require.context("../assets/sportFitness", false, /\.(png|jpe?g|svg)$/)
);

const taniec = importAll(
  require.context("../assets/taniec", false, /\.(png|jpe?g|svg)$/)
);

const instrumentalne = importAll(
  require.context(
    "../assets/zajeciaInstrumentalne",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const muzyczne = importAll(
  require.context("../assets/zajeciaMuzyczne", false, /\.(png|jpe?g|svg)$/)
);

const wokalne = importAll(
  require.context("../assets/zajeciaWokalne", false, /\.(png|jpe?g|svg)$/)
);

export { artystyczne, sportFitness, taniec, instrumentalne, muzyczne, wokalne };
