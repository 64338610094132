import React from 'react';
import '../css/gallery.css';

export const Gallery = ({ sources, openFullScreen }) => {
  return (
    <div className="gallery-container">
      <div className="main-image-container">
        <img
          src={sources[0]}
          alt="Main"
          className="main-image"
        />
      </div>
      <div className="thumbnails-container">
        {sources.map((source, index) => (
          <img
            key={index}
            src={source}
            alt={`Thumbnail ${index}`}
            className="thumbnail"
            onClick={() => openFullScreen(source)}
          />
        ))}
      </div>
    </div>
  );
};
